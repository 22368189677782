import { DownCircleOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import * as number from "../../../../utils/number";
import Category from "./Category";

function TableJournals(props) {
  const [data, setData] = useState([]);
  const [activeOperations, setActiveOperations] = useState([]);
  const [styleColumns, setStyleColumns] = useState([]);

  useEffect(() => {
    var columns = props.accountColumns;
    columns.forEach((col, i) => {
      switch (i) {
        case 0:
          col.width = "70px";
          break;
        case 1:
          col.width = "250px";
          break;
        case 2:
          col.width = "230px";
          break;
        case 3:
          col.width = "190px";
          break;
        case 4:
          col.width = "190px";
          break;
        /* case 5:
          col.width = "190px";
          break; */
        default:
          break;
      }
    });
    setStyleColumns(columns);
  }, []);

  useEffect(() => {
    if (props.categories.length === 0) return;

    const operationsByJournal = {};

    props.categories.forEach((account) => {
      account.operations.forEach((operation) => {
        const type = operation.amount >= 0 ? "ventes" : "achats";

        if (!operationsByJournal[operation.journal]) {
          operationsByJournal[operation.journal] = { ventes: {}, achats: {} };
        }

        if (!operationsByJournal[operation.journal][type][account._id]) {
          operationsByJournal[operation.journal][type][account._id] = {
            title: account.title,
            accountNumberAux: account.accountNumberAux,
            operations: [],
          };
        }

        operationsByJournal[operation.journal][type][account._id].operations.push(operation);
      });
    });

    if (!operationsByJournal[props.id]?.ventes || !operationsByJournal[props.id]?.achats) return;

    setData(
      [
        {
          id: "ven",
          label: "Ventes",
          key: "ventes",
          data: Object.keys(operationsByJournal[props.id].ventes).map((j) => ({
            _id: j + props.id + "ventes",
            key: j + props.id + "ventes",
            accountNumberAux: operationsByJournal[props.id].ventes[j].accountNumberAux,
            title: operationsByJournal[props.id].ventes[j].title,
            operations: operationsByJournal[props.id].ventes[j].operations,
            numberMissingDoc: operationsByJournal[props.id].ventes[j].operations.length,
            amount: operationsByJournal[props.id].ventes[j].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
        {
          id: "ach",
          label: "Achats",
          key: "achats",
          data: Object.keys(operationsByJournal[props.id].achats).map((j) => ({
            _id: j + props.id + "achats",
            key: j + props.id + "achats",
            accountNumberAux: operationsByJournal[props.id].achats[j].accountNumberAux,
            title: operationsByJournal[props.id].achats[j].title,
            operations: operationsByJournal[props.id].achats[j].operations,
            numberMissingDoc: operationsByJournal[props.id].achats[j].operations.length,
            amount: operationsByJournal[props.id].achats[j].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
      ]
        .map((el) => ({ ...el, totalAmount: el.data.reduce((acc, curr) => acc + curr.amount, 0) }))
        .filter((el) => el.totalAmount > 0)
    );

    setActiveOperations(
      props.categories
        .map(({ operations }) => operations.filter((op) => op.active !== false))
        .flat(1)
    );
  }, [props.categories, props.id]);

  const uncheckOperations = (categoryId, uncheck) => {
    console.debug("uncheckOperations()");
    props.setAllData([
      ...props.allData.map((el) =>
        el.id !== props.id
          ? el
          : {
              ...el,
              data: props.categories.map((cat) =>
                cat._id !== categoryId
                  ? cat
                  : {
                      ...cat,
                      operations: cat.operations.map((op) => ({ ...op, active: !uncheck })),
                    }
              ),
            }
      ),
    ]);
  };

  return (
    <>
      <Collapse
        className="type-journal-collapse"
        defaultActiveKey={["ven", "ach"]}
        items={data.map((el) => ({
          key: el.id,
          label: (
            <div className="journal-type-title">
              {el.label} - {number.withThousandSeparator(number.parseNum(el.totalAmount))}
            </div>
          ),
          children: (
            <Table
              className="missing-table"
              showHeader={false}
              dataSource={el.data}
              columns={styleColumns}
              rowSelection={
                props.editMode && {
                  renderCell: (checked, record) => {
                    const activeOperationsMap = new Map(
                      record.operations.map((op) => [op._id, op])
                    );

                    const totalOperations = record.operations.length;
                    const activeOperationsCount = activeOperations.reduce(
                      (count, op) => (activeOperationsMap.has(op._id) ? count + 1 : count),
                      0
                    );

                    return (
                      <Checkbox
                        indeterminate={
                          activeOperationsCount > 0 && activeOperationsCount < totalOperations
                        }
                        checked={activeOperationsCount === totalOperations}
                        onChange={(e) => {
                          const uncheck = !e.target.checked;
                          uncheckOperations(record._id, uncheck);
                        }}
                      />
                    );
                  },
                  hideSelectAll: true,
                }
              }
              expandable={{
                expandedRowKeys: props.expandedRowKeys,
                expandedRowRender: (record) => (
                  <Category {...props} category={record} key={record._id} />
                ),
                expandRowByClick: true,
                onExpandedRowsChange: (rows) => {
                  props.setExpandedRows(rows);
                },
                expandIcon: ({ expanded, onExpand, record }) => (
                  <DownCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className={expanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                ),
              }}
            />
          ),
        }))}
        expandIconPosition="end"
        bordered={false}
        expandIcon={({ isActive }) => (
          <>
            <DownCircleOutlined
              style={{ fontSize: "20px" }}
              className={isActive ? "expand-icon-down" : "expand-icon-up"}
            />{" "}
          </>
        )}
      />
    </>
  );
}

export default TableJournals;
