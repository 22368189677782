import { Table, Typography } from "antd";
import {
  formatDataHelper,
  getAmountFilteredHelper,
  getNumberFilteredHelper,
} from "helpers/client/clientOutstandingsHelper";
import { useEffect, useState } from "react";
import * as number from "../../../../../utils/number";
import Comment from "./Comment";
import CommentCollab from "./CommentCollab";
import "./OutstandingsClientDesktop.css";

const { Text } = Typography;

const Detailed = (props) => {
  const [accountData, setAccountData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    setAccountData(formatDataHelper(props.data, props.endDate));
    setExpandedRowKeys(
      props.data
        .map((cat) => {
          return cat.operations.map(({ id }) => id);
        })
        .flat(1)
    );
  }, [props.data]);

  useEffect(() => {
    props.setTotalAmountFiltered(getAmountFilteredHelper(accountData));
    props.setTotalNumberFiltered(getNumberFilteredHelper(accountData));
  }, [accountData]);

  const expandedRowRender = (record) => {
    let nbrMissing = 0;
    let amountMissing = 0;
    const categories = props.missingDoc.filter((acc) => acc.title === record.accountLabel);

    if (categories.length > 0) {
      categories.forEach((cat) => {
        nbrMissing += cat.operations.length;
        cat.operations.forEach((op) => {
          amountMissing += Math.abs(op.amount);
        });
      });
    }

    const text =
      nbrMissing > 1 ? (
        <p className="missing-doc-info-p">
          Par ailleurs, il y a{" "}
          <span className="missing-doc-info-span">{nbrMissing} pièces manquantes</span> sur ce
          compte, pour un montant de {number.parseToAmount(amountMissing)}.
        </p>
      ) : nbrMissing === 1 ? (
        <p className="missing-doc-info-p">
          Par ailleurs, il y a <span className="missing-doc-info-span">1 pièce manquante</span> sur
          ce compte, pour un montant de {number.parseToAmount(amountMissing)}.
        </p>
      ) : null;

    return (
      <>
        <Table
          className="outstandings-sub-table"
          columns={props.operationColumns}
          dataSource={record.operations
            .filter((op) => !op.hidden)
            .map((op) => ({ ...op, key: op.id }))}
          pagination={{ defaultPageSize: 10, showSizeChanger: true }}
          showExpandColumn={false}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <CommentCollab {...props} record={record} />
                <Comment
                  {...props}
                  record={record}
                  accountData={accountData}
                  setAccountData={setAccountData}
                />
              </>
            ),
            expandedRowKeys: expandedRowKeys,
          }}
        />
        {categories.length > 0 && nbrMissing > 0 ? (
          <div
            className="missing-doc-info"
            onClick={(e) => {
              e.preventDefault();
              props.changeTab("missingdoc");
              window.scroll(0, 0);
            }}
          >
            {text}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <span className="outstandings-sub-table-client">
      <span className="outstandings-sub-table-client">
        <Text className="section-title">Détail</Text>
        <div className="blue-bar"></div>

        <Table
          className="detailed-table outstandings-table"
          showSorterTooltip={false}
          columns={props.accountColumns}
          dataSource={accountData.filter((cat) => cat.operations.some((op) => !op.hidden))}
          expandable={{ expandedRowRender: (record) => expandedRowRender(record) }}
        />
      </span>
    </span>
  );
};

export default Detailed;
