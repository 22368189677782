import { DownCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import * as number from "../../../../../utils/number";
import Category from "./Category";

function TableJournals(props) {
  const [data, setData] = useState([]);

  const [styleColumns, setStyleColumns] = useState([]);

  useEffect(() => {
    var columns = props.accountColumns;
    columns.forEach((col, i) => {
      switch (i) {
        case 0:
          col.width = "70px";
          break;
        case 1:
          col.width = "250px";
          break;
        case 2:
          col.width = "230px";
          break;
        case 3:
          col.width = "190px";
          break;
        case 4:
          col.width = "190px";
          break;

        default:
          break;
      }
    });
    setStyleColumns(columns);
  }, []);

  useEffect(() => {
    if (props.categories.length === 0) return;

    const operationsByJournal = {};

    props.categories.forEach((item) => {
      item.operations.forEach((operation) => {
        const type = operation.amount >= 0 ? "ventes" : "achats";

        if (!operationsByJournal[operation.journal]) {
          operationsByJournal[operation.journal] = { ventes: {}, achats: {} };
        }

        if (!operationsByJournal[operation.journal][type][item.accountNumberAux]) {
          operationsByJournal[operation.journal][type][item.accountNumberAux] = {
            title: item.title,
            operations: [],
          };
        }

        operationsByJournal[operation.journal][type][item.accountNumberAux].operations.push(
          operation
        );
      });
    });

    if (!operationsByJournal[props.id]?.ventes || !operationsByJournal[props.id]?.achats) return;

    setData(
      [
        {
          id: "ven",
          label: "Ventes",
          key: "ventes",
          data: Object.keys(operationsByJournal[props.id].ventes).map((accountNumberAux) => ({
            key: accountNumberAux,
            accountNumberAux,
            title: operationsByJournal[props.id].ventes[accountNumberAux].title,
            operations: operationsByJournal[props.id].ventes[accountNumberAux].operations,
            amount: operationsByJournal[props.id].ventes[accountNumberAux].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
        {
          id: "ach",
          label: "Achats",
          key: "achats",
          data: Object.keys(operationsByJournal[props.id].achats).map((accountNumberAux) => ({
            key: accountNumberAux,
            accountNumberAux,
            title: operationsByJournal[props.id].achats[accountNumberAux].title,
            operations: operationsByJournal[props.id].achats[accountNumberAux].operations,
            amount: operationsByJournal[props.id].achats[accountNumberAux].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
      ].map((el) => ({ ...el, totalAmount: el.data.reduce((acc, curr) => acc + curr.amount, 0) }))
    );
  }, [props.categories, props.id]);

  return (
    <>
      {data
        .filter((el) => el.data.length > 0)
        .map((el) => (
          <div className="type-missing-doc">
            <div className="type-title">
              {el.label} - {number.withThousandSeparator(number.parseNum(el.totalAmount))}
            </div>
            <Table
              className="missing-table"
              showHeader={false}
              dataSource={el.data}
              columns={styleColumns}
              expandable={{
                expandedRowRender: (record) => (
                  <Category {...props} category={record} key={record._id} />
                ),
                //expandedRowKeys: expandedRows,
                expandRowByClick: !props.editMode,
                // onExpandedRowsChange: (rows) => setExpandedRows(rows),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <DownCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className={expanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                ),
              }}
            />
          </div>
        ))}
    </>
  );
}

export default TableJournals;
