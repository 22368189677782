import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import ClientContext from "../../context/ClientContext";
import * as sentry from "../../utils/sentry";
import Alert from "../alert/Alert";
import DisconnectedFooter from "../footer/DisconnectedFooter";
import "./Client.css";
import ClientBody from "./components/body";
import ClientFooter from "./components/footer";
import ClientNavbar from "./components/navbar";

function Client(props) {
  const tokenClient = props.match.params.token;
  const idAccountingFirm = jwt_decode(tokenClient).accountingFirmId;
  const idClient = jwt_decode(tokenClient).clientId;
  const remoteId = jwt_decode(tokenClient).remoteId;
  const defaultView = jwt_decode(tokenClient).defaultView;

  const [quitPage, setQuitPage] = useState(false);
  const [expiredToken, setExpiredToken] = useState(false);

  useEffect(() => {
    sentry.addTag("client", remoteId);
  }, []);

  return (
    <ClientContext.Provider value={{ idClient, idAccountingFirm }}>
      {expiredToken ? (
        <Alert
          {...props}
          title="Lien expiré"
          text="Pour des raisons de sécurité, les liens Sesha expirent au bout de 60 jours. Nous vous invitons à contacter votre cabinet comptable afin d'obtenir un nouveau lien."
        />
      ) : (
        <div id="client-page">
          <ClientNavbar
            clientName={remoteId}
            idClient={idClient}
            tokenClient={tokenClient}
            accountingFirmId={idAccountingFirm}
            setQuitPage={setQuitPage}
            quitPage={quitPage}
          />
          {quitPage ? (
            <DisconnectedFooter />
          ) : (
            <>
              <ClientBody
                idClient={idClient}
                tokenClient={tokenClient}
                defaultView={defaultView}
                setExpiredToken={setExpiredToken}
              />
              <ClientFooter />
            </>
          )}
        </div>
      )}
    </ClientContext.Provider>
  );
}

export default Client;
