import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectClient } from "../../../slices/clientSlice";
import { selectUser } from "../../../slices/userSlice";

const { confirm } = Modal;

function ResetOperation(props) {
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);

  const fetchReset = async (file) => {
    const id = props.opId.replaceAll("/", "%2F");
    const data = {
      idClient: props.match.params.clientId,
      opId: props.opId,
      fileId: file._id,
    };

    const res = await API.postToResetOperation(id, data);
    console.log("Votre opération a été réinitialisée. (2)");
    return res;
  };

  const getResetSync = async () => {
    for (let index = 0; index < props.files.length; index++) {
      const file = props.files[index];
      await fetchReset(file, index);
    }
  };

  const onSubmit = () => {
    Modal.destroyAll();
    props.reseted({ opId: props.opId });
    logAction(236, 1, client._id);

    const id = props.opId.replaceAll("/", "%2F");
    const data = {
      idClient: props.match.params.clientId,
      clientName: client.name,
      opId: props.opId,
    };
    if (props.files.length > 0) {
      getResetSync();
    } else {
      (async () => {
        const res = await API.postToResetOperation(id, data);
        if (res.status === 200) {
          console.log("Votre opération a été réinitialisée.");
        }
      })();
    }
    API.postCollabTracker(props.match.params.clientId, {
      author: user.firstName + " " + user.lastName,
      action: "RESET",
      operation: props.operationText,
    });
  };

  function showConfirm() {
    confirm({
      title:
        "Vous êtes sur le point de remettre l’opération à son état d’origine. Les commentaires et/ou fichiers transmis par votre client sur cette opération seront supprimés et elle apparaitra à nouveau comme pièce manquante à traiter sur le formulaire client.",
      icon: <ExclamationCircleOutlined />,
      width: 500,
      className: "modal-invalid modal-reset",
      maskClosable: true,
      closable: true,
      content: (
        <div>
          <Button
            className="other-action-btn reset-cancel"
            onClick={(e) => {
              e.preventDefault();
              Modal.destroyAll();
            }}
          >
            Annuler
          </Button>
          <Button
            className="call-action-btn invalid-submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Valider
          </Button>
        </div>
      ),
    });
  }

  return (
    <Button
      className="invalid-file-btn call-action-btn"
      onClick={(e) => {
        e.preventDefault();
        showConfirm();
      }}
      style={{ marginLeft: "10px" }}
    >
      Réinitialiser
    </Button>
  );
}

export default ResetOperation;
