import { Progress } from "antd";
import { useState } from "react";
import * as number from "../../../../../utils/number";
import { accountColumnsDesktop, operationColumnsDesktop } from "../../../misc";
import Detailed from "./Detailed";

const OutstandingsDesktop = (props) => {
  const [totalAmountFiltered, setTotalAmountFiltered] = useState();
  const [totalNumberFiltered, setTotalNumberFiltered] = useState();

  return (
    <div style={{ minHeight: "500px" }} id="client-outstandings">
      <div className="circle outstandings-circle">
        <Progress
          type="circle"
          percent={100}
          size={80}
          strokeColor="#4569f8"
          format={() => <span className="circular-text">{totalNumberFiltered}</span>}
        />
        <div className="circle-block">
          <div className="outstandings-text">
            Factures non payées au <b>{new Date(props.endDate).toLocaleDateString()}</b>
          </div>

          <br />
          <span className="outstandings-text">Pour un montant de </span>
          <b>{number.parseToAmount(Math.abs(totalAmountFiltered))}</b>
        </div>
      </div>

      <div>
        <b>
          Si le règlement de l’une de ces factures a déjà été réalisé, merci de nous indiquer de
          quelle façon en commentaire.
        </b>
      </div>
      <div className="outstandings-dueDay">
        {props.type === "clients" ? (
          <p>
            Encours supérieurs à {props.outstandingClientDueDay || 0} jours au{" "}
            {new Date(props.endDate).toLocaleDateString()}
          </p>
        ) : (
          <p>
            Encours supérieurs à {props.outstandingProviderDueDay || 0} jours au{" "}
            {new Date(props.endDate).toLocaleDateString()}
          </p>
        )}
      </div>
      <br />
      <Detailed
        {...props}
        data={props.data}
        endDate={props.endDate}
        accountColumns={accountColumnsDesktop}
        operationColumns={operationColumnsDesktop.filter((col) => col.key !== "clientComment")}
        setTotalAmountFiltered={setTotalAmountFiltered}
        setTotalNumberFiltered={(count) => {
          setTotalNumberFiltered(count);
          props.setBadgeCount(count);
        }}
        changeTab={(key) => props.changeTab(key)}
      />
    </div>
  );
};

export default OutstandingsDesktop;
