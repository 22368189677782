import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../../api/API";
import { selectClient, updateClient } from "../../../../slices/clientSlice";

const { TextArea } = Input;

function CommentCollab(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const [operation, setOperation] = useState({});
  const [commentCollab, setCommentCollab] = useState("");
  const [currentCommentCollab, setCurrentCommentCollab] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setOperation(props.operation);
    setCommentCollab(props.operation?.commentCollab);
    setCurrentCommentCollab(props.operation?.commentCollab);
  }, [props.operation]);

  const updateCommentCollab = (value) => {
    (async () => {
      const res = await API.putOperation(client._id, encodeURIComponent(operation._id), {
        commentCollab: value || null,
      });
      if (res.status === 201) {
        console.log("Comment collab updated");
        const varLocal = client.operationCategories.map((category) => ({
          ...category,
          operations: category.operations.map((op) => {
            if (op._id === operation._id) {
              return { ...op, commentCollab: value };
            } else return op;
          }),
        }));
        dispatch(
          updateClient({
            operationCategories: varLocal,
          })
        );
        setCommentCollab(value);
        logAction(232, 1, client._id);
      } else console.log("Error");
    })();
  };

  const deleteCommentCollab = () => {
    (async () => {
      const res = await API.putOperation(client._id, encodeURIComponent(operation._id), {
        commentCollab: null,
      });
      if (res.status === 201) {
        console.log("Comment collab deleted");
        const varLocal = client.operationCategories.map((category) => ({
          ...category,
          operations: category.operations.map((op) => {
            if (op._id === operation._id) {
              return { ...op, commentCollab: "" };
            } else return op;
          }),
        }));
        dispatch(
          updateClient({
            operationCategories: varLocal,
          })
        );
        setCommentCollab("");
      } else console.log("Error");
    })();
  };

  return (
    <div className="comment-div">
      {commentCollab || editing ? (
        <>
          <div
            style={{
              display: "flex",
              // alignItems: !editing ? "center" : "",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            {!editing ? (
              <>
                <b>Commentaire cabinet :</b>
                <span
                  className="commentaire-cabinet-span"
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "3px",
                  }}
                >
                  {commentCollab}
                </span>
              </>
            ) : (
              <span className="commentaire-cabinet-edit-span">
                <TextArea
                  className="other-edit"
                  defaultValue={commentCollab}
                  placeholder="Ajouter un commentaire"
                  onChange={(e) => {
                    setCurrentCommentCollab(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    setEditing(false);
                    updateCommentCollab(e.target.value);
                  }}
                />
                <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
              </span>
            )}
            <Button
              className="other-action-btn"
              shape="circle"
              icon={<EditOutlined />}
              onClick={(e) => {
                if (editing) {
                  updateCommentCollab(currentCommentCollab);
                  setEditing(false);
                } else {
                  setEditing(true);
                }
              }}
            />
            <Button
              className="other-action-btn other-delete-btn"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                deleteCommentCollab();
                setEditing(false);
              }}
            />
          </div>
        </>
      ) : (
        <Button
          type="text"
          className="add-comment my-collapsible__toggle"
          onClick={(e) => {
            setEditing(true);
          }}
        >
          Ajouter un commentaire
        </Button>
      )}
    </div>
  );
}

export default CommentCollab;
