import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button, Tooltip, Upload } from "antd";
import OperationActions from "components/client/components/missing/desktop/OperationActions";
import dayjs from "dayjs";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";
import openNotification from "../../../../../utils/notification";
import * as number from "../../../../../utils/number";

function Operation(props) {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ClientContext);
  const decodedToken = jwt_decode(props.tokenClient);

  useEffect(() => {
    const filesToAdd = props.operation.files.map((file) => ({
      _id: file._id,
      uid: file._id,
      name: file.fileName ?? file.name,
      status: "done",
    }));
    setFileList(filesToAdd);
  }, [props.operation]);

  const handleRemove = async (file) => {
    const deleteRes = await uploadHelper.handleOnRemoveHelper(
      [file],
      props.operation,
      context.idClient,
      decodedToken
    );

    const isSuccess = deleteRes[0].status === 200;
    if (!isSuccess) {
      openNotification(
        "error",
        "Erreur lors de la suppression d'un fichier. Veuillez recharger la page."
      );
      return false;
    }
    uploadHelper.updateStatesHelper(
      fileList.filter((f) => f._id !== file._id),
      props.setData,
      props.operation,
      props.operation.comment,
      props.operation.isLost,
      props.operation.isLostNoAccount
    );
  };

  const handleBeforeUpload = (file) => {
    if (!uploadHelper.fileSizeIsOkHelper(file, props.maxFileSizeInMB)) return false;
    if (!uploadHelper.fileFormatIsOkHelper(file)) return false;
    if (!uploadHelper.fileNameIsOkHelper(file, props.operation, fileList)) return false;

    return true;
  };

  const handleUpload = async (file) => {
    setIsLoading(true);
    const response = await uploadHelper.handleUploadHelper(
      file.file,
      props.operation,
      props.category,
      decodedToken,
      props.tokenClient
    );

    if (response.status) {
      openNotification("success", "Action exécutée avec succès.");
      uploadHelper.updateStatesHelper(
        response.data.files,
        props.setData,
        props.operation,
        props.operation.comment,
        props.operation.isLost,
        props.operation.isLostNoAccount
      );
      setIsLoading(false);
      return true;
    }
    openNotification("error", response.message);
    setIsLoading(false);
    return false;
  };

  const handleDownload = async (file) => {
    await uploadHelper.downloadHelper(context.idClient, props.operation._id, file);
  };

  const getFileName = (file) => {
    const name = file.fileName ?? file.name;
    if (name.length > 37) {
      return <Tooltip title={name}>{name.substring(0, 37) + "..."}</Tooltip>;
    }
    return name;
  };

  const getOperationStatus = () => {
    if (props.operation.comment || props.operation.files.length > 0) return "isValid";
    if (
      props.operation.invalidFileComment ||
      props.operation.isLost ||
      props.operation.isLostNoAccount
    )
      return "isInvalid";
    return "";
  };

  const getOperationStatusComment = () => {
    if (
      (props.operation.files !== null && props.operation.files.length > 0) ||
      props.operation.comment ||
      props.operation.isLost ||
      props.operation.isLostNoAccount
    ) {
      return (
        <>
          <p>En cours de traitement</p>
          {props.operation.isLost && <p>[Récupérable]</p>}
          {props.operation.isLostNoAccount && <p>[Non récupérable]</p>}
        </>
      );
    }

    if (props.operation.invalidFileComment)
      return (
        <p style={{ color: "hsla(228, 93%, 62%, 1)", maxWidth: "350px" }}>
          <b>{props.operation.invalidFileComment.split("***")[0]} - Invalidé : </b>
          {props.operation.invalidFileComment.split("***")[1]}
        </p>
      );

    return null;
  };

  return (
    <div key={props.operation._id} className="client-missingDoc-operation-desktop">
      <div>
        <div>
          <div className={getOperationStatus()}>
            <span>
              <span>
                <p>{dayjs(props.operation.date).format("DD/MM/YYYY")}</p>
                <p>{props.operation.text || "[Absence de libellé]"}</p>
              </span>
              <p>
                {number.withThousandSeparator(number.parseNum(Math.abs(props.operation.amount)))}
              </p>
            </span>
          </div>

          <Upload
            beforeUpload={(file) => handleBeforeUpload(file)}
            showUploadList={false}
            customRequest={async (info) => await handleUpload(info)}
          >
            <Button
              loading={isLoading}
              type="primary"
              icon={<CloudUploadOutlined />}
              style={{ width: "80px", borderRadius: "50px" }}
            />
          </Upload>
        </div>

        <div>
          <div style={{ marginTop: "10px" }}>
            {props.operation.commentCollab && (
              <p style={{ padding: "5px 10px" }}>
                <b>Commentaire cabinet : </b>
                {props.operation.commentCollab}
              </p>
            )}

            <div className="client-missingDoc-addComment">
              <OperationActions {...props} operation={props.operation} />
            </div>

            <div>
              {fileList &&
                fileList.length > 0 &&
                fileList.map((file, index) => (
                  <span
                    className="client-upload-file-list-not-edit-mode"
                    key={`file upload list client desktop ${index}`}
                  >
                    <PaperClipOutlined />
                    <Button type="link" onClick={async () => await handleDownload(file)}>
                      {getFileName(file)}
                    </Button>
                    <Button type="text" onClick={async () => await handleRemove(file)}>
                      <DeleteOutlined />
                    </Button>
                  </span>
                ))}
            </div>
          </div>
          {props.title !== "Autres" ? (
            <div className="client-missingDoc-op-state">{getOperationStatusComment()}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Operation;
