import { CommentOutlined } from "@ant-design/icons";
import { Checkbox, Col, InputNumber, Modal, Popover, Row, Table, Tag, Typography } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import AccountGlobalTable from "../AccountGlobalTable";
import ExpandedRowRender from "./ExpandedRowRender";

const { Text } = Typography;

const filterOperations = (operations) =>
  operations
    ?.filter((op) => !op.filteredParams)
    .filter((op) => !op.filteredDueDays)
    .filter((op) => !op.unchecked) || [];

const Detailed = (props) => {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [accountData, setAccountData] = useState(props.data);
  const [showModal, setShowModal] = useState(false);
  const [record, setRecord] = useState({});
  const [activeOperationsIds, setActiveOperationsIds] = useState([]);
  const [dueDay, setDueDay] = useState(0);

  useEffect(() => {
    if (client.status === "ready") {
      setAccountData(
        [...accountData].map((cat) => ({
          ...cat,
          key: cat._id,
          operations: cat.operations.map((op) => ({
            ...op,
            key: op.id,
            dueDays: dayjs(client.outstandingsParam[props.type].endDate)
              .startOf("day")
              .diff(dayjs(op.date).startOf("day"), "days"),
            filteredParams:
              !props.journalList.includes(op.journal?.toUpperCase()) ||
              new Date(op.date) < new Date(props.startDate) ||
              new Date(op.date) > new Date(props.endDate),
            filteredDueDays:
              client.outstandingsParam[props.type]?.dueDays >
                dayjs(client.outstandingsParam[props.type].endDate)
                  .startOf("day")
                  .diff(dayjs(op.date).startOf("day"), "days") || false,
          })),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.journalList, props.startDate, props.endDate]);

  useEffect(() => {
    props.setData(accountData);
    props.setActiveOperations(activeOperationsIds);
    props.setActiveOperationsAmount(
      accountData.reduce(
        (acc, current) =>
          acc +
          filterOperations(current.operations).reduce((acc, current) => acc + current.amount, 0),
        0
      ) || 0
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOperationsIds]);

  useEffect(() => {
    if (client.status === "ready") {
      setDueDay(client.outstandingsParam[props.type]?.dueDays || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDays = (value) => {
    setDueDay(Math.abs(value));

    const timer = setTimeout(() => {
      setAccountData(
        [...accountData].map((cat) => ({
          ...cat,
          operations: cat.operations.map((op) => ({
            ...op,
            filteredDueDays: Math.abs(op.dueDays) < value,
          })),
        }))
      );
      (async () => {
        const data = { ["outstandingsParam." + props.type + ".dueDays"]: Math.abs(value) };
        const res = await API.putClient(client._id, data);
        if (res.status === 201) {
          dispatch(
            updateClient({
              outstandingsParam: {
                ...client.outstandingsParam,
                [props.type]: {
                  ...client.outstandingsParam[props.type],
                  dueDays: Math.abs(value),
                },
              },
            })
          );
        }
      })();
    }, 1000);

    return () => clearTimeout(timer);
  };

  // check or uncheck operations in accountData
  const uncheckOperations = (operationsId, uncheck) => {
    setAccountData(
      [...accountData].map((cat) => ({
        ...cat,
        operations: cat.operations.map((op) => {
          if (!operationsId.includes(op.id)) return op;
          return { ...op, unchecked: uncheck };
        }),
      }))
    );
  };

  useEffect(() => {
    setActiveOperationsIds(
      accountData.reduce(
        (acc, current) => acc.concat(filterOperations(current.operations).map((op) => op.id)),
        []
      )
    );
  }, [accountData]);

  const unselectAll = async () => {
    setAccountData(
      props.data.map((cat) => ({
        ...cat,
        operations: cat.operations.map((op) => {
          return { ...op, unchecked: true };
        }),
      }))
    );

    for (var i = 0; i < props.data.length; i++) {
      if (!props.data[i].unchecked) {
        await API.putUncheckOperations(client._id, props.type, props.data[i]._id, {
          uncheck: true,
        });
      }
    }
  };

  const selectAll = async () => {
    setAccountData(
      props.data.map((cat) => ({
        ...cat,
        operations: cat.operations.map((op) => {
          return { ...op, unchecked: false };
        }),
      }))
    );

    for (var i = 0; i < props.data.length; i++) {
      if (props.data[i].unchecked) {
        await API.putUncheckOperations(client._id, props.type, props.data[i]._id, {
          uncheck: false,
        });
      }
    }
  };

  const columns = props.accountColumns.map((column) => {
    if (column.dataIndex === "accountNumber") {
      return {
        ...column,
        render: (text, record) => {
          const nbrCommentClient = record.operations.reduce(
            (count, op) => (op.commentClient ? count + 1 : count),
            0
          );

          return (
            <>
              <span>{text}</span>
              {record.operations.some((op) => op.commentClient) && (
                <Popover
                  content={
                    nbrCommentClient > 1
                      ? `${nbrCommentClient} commentaires clients`
                      : `${nbrCommentClient} commentaire client`
                  }
                >
                  <Tag color="var(--blue)" style={{ marginLeft: "10px" }} borderless>
                    <span style={{ color: "white" }}>
                      {record.operations.reduce(
                        (count, op) => (op.commentClient ? count + 1 : count),
                        0
                      )}
                      <CommentOutlined style={{ marginLeft: "5px" }} />
                    </span>
                  </Tag>
                </Popover>
              )}
            </>
          );
        },
      };
    }
    return column;
  });

  //TODO :  logAction(props.type === "providers" ? 263 : 242, 1, client._id);

  return (
    <>
      {client.status === "ready" ? (
        <>
          <Row>
            <Col span={2}>
              <Text className="section-title">Détail</Text>
              <div className="blue-bar"></div>
            </Col>
            <Col span={10}>
              <div className="select-all">
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    unselectAll();
                  }}
                >
                  Tout désélectionner
                </a>
                <span> / </span>
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    selectAll();
                  }}
                >
                  Tout sélectionner
                </a>
              </div>
            </Col>
            <Col span={12}>
              <Text style={{ float: "right" }}>
                N'afficher que les encours supérieurs à{" "}
                <InputNumber
                  className="outstandings-input"
                  value={dueDay}
                  onChange={onChangeDays}
                />{" "}
                jours
              </Text>
            </Col>
          </Row>
          <Table
            className="detailed-table outstandings-table"
            showSorterTooltip={false}
            columns={columns}
            dataSource={accountData
              .filter((cat) =>
                cat.operations.some((op) => !op.filteredParams && !op.filteredDueDays)
              )
              .map((cat) => ({
                ...cat,
                key: cat._id,
                operations: cat.operations
                  .filter((op) => !op.filteredParams)
                  .filter((op) => !op.filteredDueDays)
                  .map((op) => ({
                    ...op,
                    idCategory: cat._id,
                    amount: props.type === "clients" ? op.amount * -1 : op.amount,
                  })),
              }))}
            rowSelection={{
              renderCell: (checked, record) => {
                const activeOperationsMap = new Map(record.operations.map((op) => [op.id, op]));

                const totalOperations = record.operations.length;
                const activeOperationsCount = activeOperationsIds.reduce(
                  (count, id) => (activeOperationsMap.has(id) ? count + 1 : count),
                  0
                );

                return (
                  <Checkbox
                    indeterminate={
                      activeOperationsCount > 0 && activeOperationsCount < totalOperations
                    }
                    checked={activeOperationsCount === totalOperations}
                    onChange={(e) => {
                      const uncheck = !e.target.checked;
                      uncheckOperations(
                        record.operations.map((op) => op.id),
                        uncheck
                      );
                      API.putUncheckOperations(client._id, props.type, record._id, {
                        uncheck,
                      });
                    }}
                  />
                );
              },
              hideSelectAll: true,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedRowRender
                  {...props}
                  record={record}
                  activeOperationsIds={activeOperationsIds}
                  uncheckOperations={uncheckOperations}
                  setShowModal={setShowModal}
                  setRecord={setRecord}
                  accountData={accountData}
                  setAccountData={setAccountData}
                />
              ),
            }}
          />

          <Modal
            title={record.accountNumber + " - " + record.accountLabel}
            className="all-op-modal"
            open={showModal}
            onCancel={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
            footer={null}
          >
            <AccountGlobalTable {...props} account={record.accountNumber} />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Detailed;
