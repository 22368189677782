import { DownCircleOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import {
  filterViewInnerJournalsHelper,
  getSumHelper,
} from "../../../../../helpers/client/clientMissingDocHelper";
import ClientMissingTablesResponsiveCard from "./tableResponsiveCard";

const ClientMissingTablesResponsive = (props) => {
  const [data, setData] = useState([]);
  const [expandedJournalList, setExpandedJournalList] = useState([]);
  const [expandedAccounts, setExpandedAccounts] = useState({});

  const notJournalTypesList = ["enc", "dec", "ach", "ven", "att"];

  useEffect(() => {
    const dataSource = props.missingDocument.map((element) => {
      return {
        ...element,
        title: `${
          notJournalTypesList.includes(props.type) ? element.title : element.accountNumberAux
        }`,
        // isExpanded: false,
        amount: element.operations.reduce(
          (accumulator, obj) => accumulator + Math.abs(obj.amount),
          0
        ),
        operations: element.operations
          ? element.operations.map((op) => {
              return { ...op, isInEditMode: false };
            })
          : [],
      };
    });
    setData(dataSource);

    if (!notJournalTypesList.includes(props.type)) {
      setExpandedJournalList({ key: props.type, achIsExpanded: false, venIsExpanded: false });
    }

    if (Object.keys(expandedAccounts).length === 0) {
      let accounts = {};

      props.missingDocument.forEach((element) => {
        accounts[element._id] = false;
      });
      setExpandedAccounts(accounts);
    }
  }, [props.missingDocument, props.type]);

  if (!notJournalTypesList.includes(props.type)) {
    return data.length === 0 ? (
      <Skeleton active />
    ) : (
      <div className={"client-table-responsive-journals"}>
        <span>
          <h3>{props.label}</h3>
          <div></div>
        </span>
        {filterViewInnerJournalsHelper(data).map((missingDocument, index) => {
          if (missingDocument.data.length === 0) return <></>;
          const sum = getSumHelper(missingDocument.data);

          return (
            <div key={`journals-responsive-key ${index}`}>
              <span
                onClick={() => {
                  setExpandedJournalList((previous) => ({
                    ...previous,
                    [`${missingDocument.id}IsExpanded`]:
                      !previous[`${missingDocument.id}IsExpanded`],
                  }));
                }}
              >
                <DownCircleOutlined
                  className={
                    !expandedJournalList[`${missingDocument.id}IsExpanded`]
                      ? "expand-icon-down"
                      : "expand-icon-up"
                  }
                />
                <h3>
                  {missingDocument.label} - {parseFloat(sum).toFixed(2)} €
                </h3>
              </span>
              <div
                style={{
                  display: expandedJournalList[`${missingDocument.id}IsExpanded`]
                    ? "block"
                    : "none",
                }}
              >
                <ClientMissingTablesResponsiveCard
                  data={missingDocument.data}
                  label={missingDocument.label}
                  setData={setData}
                  expandedAccounts={expandedAccounts}
                  setExpandedAccounts={setExpandedAccounts}
                  setMissingDocument={props.setMissingDocument}
                  type={props.type}
                  tokenClient={props.tokenClient}
                  missingDocLostMessage={props.missingDocLostMessage}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    data.length === 0 && <Skeleton active />,
    (
      <ClientMissingTablesResponsiveCard
        data={data}
        label={props.label}
        setData={setData}
        expandedAccounts={expandedAccounts}
        setExpandedAccounts={setExpandedAccounts}
        setMissingDocument={props.setMissingDocument}
        tokenClient={props.tokenClient}
        missingDocLostMessage={props.missingDocLostMessage}
      />
    )
  );
};

export default ClientMissingTablesResponsive;
