import { Button, Progress, Select } from "antd";
import { getNumberOfMissingDocumentsHelper } from "helpers/client/clientMissingDocHelper";
import { useEffect, useState } from "react";

function ClientBodyStats(props) {
  const [sum, setSum] = useState(0);
  const [justificationDate, setJustificationDate] = useState("");
  const [numberOfMissingDocuments, setNumberOfMissingDocuments] = useState(0);
  const [fiscalYearEnd, setFiscalYearEnd] = useState(0);

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    // Déterminer l'année de clôture
    let fiscalYearEnd;
    if (currentMonth >= props.fiscalStartMonth) {
      fiscalYearEnd = currentYear + 1;
    } else {
      fiscalYearEnd = currentYear;
    }

    setFiscalYearEnd(fiscalYearEnd);
  }, []);

  useEffect(() => {
    const calculatedSum = props.missingDocument
      .map((element) => {
        let calculatedSum = 0;

        element.data.forEach((obj) => {
          calculatedSum += obj.operations.reduce((accumulator, operation) => {
            // if (operation.files.length > 0) return accumulator;
            return accumulator + Math.abs(operation.amount);
          }, 0);
        });

        return calculatedSum;
      })
      .reduce((accumulator, obj) => accumulator + obj, 0);
    setSum(calculatedSum);

    setNumberOfMissingDocuments(
      getNumberOfMissingDocumentsHelper(props.missingDocument, props.missingDocumentOther)
    );
  }, [props.missingDocument, props.missingDocumentOther, props.lastMailSent]);

  useEffect(() => {
    setJustificationDate(props.lastMailSent);
  }, [props.lastMailSent, props.missingDocument, props.missingDocumentOther]);

  const getProgressCircleSize = () => {
    if (props.screenWidth > 460) return 60;
    return 50;
  };

  const getOptions = () => {
    if (props.screenWidth < 580) {
      const responsiveOptions = ["Encaissements/décaissements", "Achats/ventes", "Journaux"].map(
        (item, index) => ({
          label: `Vue ${item}`,
          value: index,
        })
      );
      return responsiveOptions;
    }
    const options = ["Encaissements/décaissements", "Achats/ventes", "Journaux"].map(
      (item, index) => ({
        label: item,
        value: index,
      })
    );

    return options;
  };

  return (
    <section>
      {props.screenWidth >= 580 ? (
        <>
          <div id="client-stats-progress-circle">
            <Progress
              type="circle"
              size={getProgressCircleSize()}
              strokeWidth={6}
              strokeColor="hsla(36, 100%, 50%, 1)"
              percent={100}
              format={() => {
                return (
                  <p>
                    <b>{numberOfMissingDocuments}</b>
                  </p>
                );
              }}
            />
            <p>
              {numberOfMissingDocuments > 0 ? "Justificatifs manquants" : "Justificatif manquant"}{" "}
            </p>
          </div>
          <div>
            <div id="client-stats-sum-unjustified">
              <p>
                {parseFloat(sum)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  .replace(".", ",") + " €"}{" "}
              </p>
              <p>Non justifiés au {justificationDate}</p>
            </div>
            <div id="client-filter-options">
              <span>
                <p>Choisir une vue :</p>
                <Select
                  defaultValue={getOptions()[props.view - 1].label}
                  value={getOptions()[props.view - 1].label}
                  style={{
                    width: 200,
                  }}
                  options={getOptions()}
                  onChange={(value) => props.setView(value + 1)}
                />
              </span>
              <span>
                <p>Afficher les exercices clôturant en :</p>
                <Button.Group>
                  <Button
                    type={props.closeDateIsSelected[0] ? "primary" : "default"}
                    onClick={() => {
                      props.setCloseDateIsSelected((previous) => [
                        !previous[0],
                        previous[1],
                        previous[2],
                      ]);
                    }}
                  >
                    {fiscalYearEnd - 2}
                  </Button>
                  <Button
                    type={props.closeDateIsSelected[1] ? "primary" : "default"}
                    onClick={() => {
                      props.setCloseDateIsSelected((previous) => [
                        previous[0],
                        !previous[1],
                        previous[2],
                      ]);
                    }}
                  >
                    {fiscalYearEnd - 1}
                  </Button>
                  <Button
                    type={props.closeDateIsSelected[2] ? "primary" : "default"}
                    onClick={() => {
                      props.setCloseDateIsSelected((previous) => [
                        previous[0],
                        previous[1],
                        !previous[2],
                      ]);
                    }}
                  >
                    {fiscalYearEnd}
                  </Button>
                </Button.Group>
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="client-stats-progress-circle">
            <Progress
              type="circle"
              size={getProgressCircleSize()}
              strokeWidth={5}
              strokeColor="hsla(36, 100%, 50%, 1)"
              percent={100}
              format={() => {
                return (
                  <p>
                    <b>{numberOfMissingDocuments}</b>
                  </p>
                );
              }}
            />
            <p>
              {numberOfMissingDocuments > 0 ? "Justificatifs manquants" : "Justificatif manquant"}{" "}
            </p>
          </div>
          <div id="client-stats-sum-unjustified">
            <p>
              {parseFloat(sum)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                .replace(".", ",") + " €"}{" "}
            </p>
            <p>Non justifiés</p>
          </div>
          <div id="client-filter-options">
            <span>
              <Select
                defaultValue={getOptions()[props.view - 1].label}
                value={getOptions()[props.view - 1].label}
                style={{
                  width: 200,
                }}
                options={getOptions("mobile")}
                onChange={(value) => props.setView(value + 1)}
              />
            </span>
            <span>
              <p>Afficher les exercices clôturant en :</p>
              <Button.Group>
                <Button
                  type={props.closeDateIsSelected[0] ? "primary" : "default"}
                  onClick={() => {
                    props.setCloseDateIsSelected((previous) => [
                      !previous[0],
                      previous[1],
                      previous[2],
                    ]);
                  }}
                >
                  2022
                </Button>
                <Button
                  type={props.closeDateIsSelected[1] ? "primary" : "default"}
                  onClick={() => {
                    props.setCloseDateIsSelected((previous) => [
                      previous[0],
                      !previous[1],
                      previous[2],
                    ]);
                  }}
                >
                  2023
                </Button>
                <Button
                  type={props.closeDateIsSelected[2] ? "primary" : "default"}
                  onClick={() => {
                    props.setCloseDateIsSelected((previous) => [
                      previous[0],
                      previous[1],
                      !previous[2],
                    ]);
                  }}
                >
                  2024
                </Button>
              </Button.Group>
            </span>
          </div>
        </>
      )}
    </section>
  );
}

export default ClientBodyStats;
