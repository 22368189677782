import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";

import { Button, Card, Col, Divider, Row, Tag, Tooltip, Upload } from "antd";
import * as dayjs from "dayjs";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";
import openNotification from "../../../../../utils/notification";
import OperationActions from "./OperationActions";

const ClientOther = (props) => {
  const [fileList, setFileList] = useState();

  const context = useContext(ClientContext);
  const decodedToken = jwt_decode(props.tokenClient);

  const handleRemove = async (file) => {
    const deleteRes = await uploadHelper.handleOnRemoveHelper(
      [file],
      props.operation,
      context.idClient,
      decodedToken
    );

    const isSuccess = deleteRes[0].status === 200;
    if (!isSuccess) {
      openNotification(
        "error",
        "Erreur lors de la suppression d'un fichier. Veuillez recharger la page."
      );
      return false;
    }
    uploadHelper.updateStatesHelper(
      fileList.filter((f) => f._id !== file._id),
      props.setData,
      props.operation,
      props.operation.comment,
      props.operation.isLost,
      props.operation.isLostNoAccount
    );
  };

  const handleBeforeUpload = (file) => {
    if (!uploadHelper.fileSizeIsOkHelper(file, props.maxFileSizeInMB)) return false;
    if (!uploadHelper.fileFormatIsOkHelper(file)) return false;
    if (!uploadHelper.fileNameIsOkHelper(file, props.operation, fileList)) return false;

    return true;
  };

  const handleUpload = async (file) => {
    const response = await uploadHelper.handleUploadHelper(
      file.file,
      props.operation,
      props.category,
      decodedToken,
      props.tokenClient,
      true
    );

    if (response.status) {
      openNotification("success", "Action exécutée avec succès.");
      uploadHelper.updateStatesHelper(
        response.data.files,
        props.setData,
        props.operation,
        props.operation.comment,
        props.operation.isLost,
        props.operation.isLostNoAccount
      );
      return true;
    }
    openNotification("error", response.message);
    return false;
  };

  useEffect(() => {
    const filesToAdd = props.operation.files.map((file) => ({
      _id: file._id,
      uid: file._id,
      name: file.fileName ?? file.name,
      status: "done",
    }));
    setFileList(filesToAdd);
  }, [props.operation]);

  const handleDownload = async (file) => {
    await uploadHelper.downloadHelper(context.idClient, props.operation._id, file);
  };

  const handleAttachmentDownload = async (file) => {
    await uploadHelper.downloadAttachmentHelper(file);
  };

  function changeClass() {
    if (props.operation.comment || props.operation.files.length > 0) return "status-green";
    return "";
  }

  const getFileName = (file) => {
    const name = file.fileName ?? file.name;
    if (name.length > 37) {
      return <Tooltip title={name}>{name.substring(0, 37) + "..."}</Tooltip>;
    }
    return name;
  };

  return (
    <div className="client-others">
      <Card
        style={{
          border:
            props.operation.comment || props.operation.files.length > 0
              ? "2px solid hsla(142, 34%, 82%, 1)"
              : "2px solid hsla(213, 100%, 89%, 1)",
          background:
            props.operation.comment || props.operation.files.length > 0
              ? "hsla(142, 28%, 92%, 1)"
              : "#EAF4FE",
        }}
        className={"other-card " + changeClass()}
      >
        <Row>
          <Col xs={22} sm={22} md={22} lg={3} xl={3}>
            <span className="data-op">{dayjs(props.operation.date).format("DD/MM/YYYY")}</span>
          </Col>
          <Col xs={22} sm={22} md={22} lg={15} xl={15}>
            <div
              dangerouslySetInnerHTML={{ __html: props.operation.text }}
              style={{ display: "inline-block" }}
            />
          </Col>
          <Col xs={22} sm={22} md={22} lg={4} xl={4} offset={2} style={{ textAlign: "center" }}>
            {fileList !== undefined && (
              <Upload
                fileList={fileList}
                listType="text"
                showUploadList={false}
                beforeUpload={(file) => handleBeforeUpload(file)}
                customRequest={async (info) => await handleUpload(info)}
                onRemove={async (file) => await handleRemove(file)}
              >
                <Button
                  type="primary"
                  icon={<CloudUploadOutlined />}
                  style={{ width: "80px", borderRadius: "50px" }}
                ></Button>
              </Upload>
            )}
          </Col>
        </Row>
        {props.operation.attachments?.length > 0 && (
          <Row>
            <Divider style={{ margin: "15px 0 10px 0" }} />
            <div style={{ display: "block", width: "90%" }}>
              {props.operation.attachments?.map((file, fileIndex) => (
                <Tag
                  key={fileIndex}
                  style={{
                    cursor: "pointer",
                  }}
                  color="grey"
                  icon={<PaperClipOutlined />}
                  onClick={async () => await handleAttachmentDownload(file)}
                >
                  {file.fileName}
                </Tag>
              ))}
            </div>
          </Row>
        )}
      </Card>

      <div>
        <OperationActions {...props} operation={props.operation} title="Autres" />

        <div className="status-txt">
          {props.operation.files !== null && props.operation.files.length > 0 ? (
            <p>En cours de traitement</p>
          ) : null}
          {props.operation.comment &&
          (props.operation.files === null || props.operation.files.length === 0) ? (
            <p>Commentaire en cours de traitement</p>
          ) : null}

          {props.operation.invalidComment &&
          !props.operation.comment &&
          (props.operation.files === null || props.operation.files.length === 0) ? (
            <p style={{ padding: "0px 10px 5px 10px", color: "hsla(228, 93%, 62%, 1)" }}>
              <b>{props.operation.invalidComment.split("***")[0]} - Invalidé : </b>
              {props.operation.invalidComment.split("***")[1]}
            </p>
          ) : null}
        </div>
      </div>

      <div>
        {fileList &&
          fileList.length > 0 &&
          fileList.map((file, index) => (
            <span
              className="client-upload-file-list-not-edit-mode"
              key={`file upload list client desktop ${index}`}
            >
              <PaperClipOutlined />
              <Button type="link" onClick={async () => await handleDownload(file)}>
                {getFileName(file)}
              </Button>
              <Button type="text" onClick={async () => await handleRemove(file)}>
                <DeleteOutlined />
              </Button>
            </span>
          ))}
      </div>
    </div>
  );
};

export default ClientOther;
