import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Divider, Modal, Select, Tag } from "antd";
import axios from "axios";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TipTapEditor from "utils/tiptap/TipTapEditorOthers";
import { v4 as uuidv4 } from "uuid";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import openNotification from "../../../utils/notification";

const { Option } = Select;
const { confirm } = Modal;

function Other(props) {
  const dispatch = useDispatch();
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);
  const tokenClient = localStorage.getItem("token");

  const [otherMissingDocuments, setOtherMissingDocuments] = useState([]);
  const [listOthers, setListOthers] = useState([]);
  const [selectedList, setSelectedList] = useState({});
  const [isEditing, setIsEditing] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      setListOthers(accountingFirm.othersTemplate);
    }
    if (client.status === "ready") {
      setOtherMissingDocuments(client.otherMissingDocuments);
    }
  }, [client.otherMissingDocuments]);

  const startEditing = (index, content, fileList) => {
    setIsEditing(index);
    setEditedContent(content);
    setSelectedFileList(fileList);
  };

  const cancelEditing = () => {
    setIsEditing(null);
    setEditedContent("");
    setSelectedFileList([]);
  };

  const handleAttachmentDownload = async (file) => {
    await uploadHelper.downloadAttachmentHelper(file);
  };

  const addOthersFromList = () => {
    (async () => {
      let updatedList = [...otherMissingDocuments];
      let newAttachments = [];
      for (const item of selectedList.list) {
        if (item.fileList.length > 0) {
          for (const file of item.fileList) {
            let resCopy = await API.createOtherAttachmentFileCopy({ fileId: file.fileId });
            resCopy = await resCopy.json();
            newAttachments = item.fileList.map((file) => ({
              ...file,
              fileId: resCopy.fileId,
            }));
          }
        }

        const uuid = uuidv4();
        let res = await API.putOperation(client._id, uuid, {
          other: true,
          text: item.content,
          attachments: newAttachments,
          files: [],
          date: new Date(),
        });

        if (res.status === 201) {
          res = await res.json();
          updatedList.push(res);
        } else {
          console.log("error");
        }
      }
      setOtherMissingDocuments(updatedList);
      dispatch(updateClient({ otherMissingDocuments: updatedList }));
    })();
  };

  const addOMD = (item) => {
    const url = process.env.REACT_APP_INTERFACE_BACKEND + "/other/file";
    const newAttachments = [...item.fileList];

    const uuid = uuidv4();
    (async () => {
      for (const file of newAttachments) {
        if (file.fileId.startsWith("rc-upload")) {
          const formData = new FormData();
          formData.append("files", file.fileInfo, file.fileName);

          axios({
            url,
            method: "post",
            headers: {
              Authorization: `Bearer ${tokenClient}`,
            },
            processData: false,
            data: formData,
          })
            .then((response) => {
              file.fileId = response.data.fileId;
              delete file.fileInfo;
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }

      const res = await API.putOperation(client._id, uuid, {
        other: true,
        text: item.content,
        attachments: newAttachments,
        files: [],
        date: new Date(),
      });
      if (res.status === 201) {
        const newList = [
          ...client.otherMissingDocuments,
          {
            _id: uuid,
            other: true,
            text: item.content,
            attachments: newAttachments,
            files: [],
            date: new Date(),
          },
        ];
        setOtherMissingDocuments(newList);
        dispatch(updateClient({ otherMissingDocuments: newList }));
      } else console.log("error");
    })();
  };

  const updateOMD = async (idOMD, updatedItem) => {
    const url = process.env.REACT_APP_INTERFACE_BACKEND + "/other/file";

    let newAttachments = [
      ...otherMissingDocuments.find((other) => other._id === idOMD).attachments,
    ];

    const otherToUpdate = otherMissingDocuments.find((other) => other._id === idOMD);

    const uploadPromises = updatedItem.fileList.map(async (file) => {
      if (file.fileId.startsWith("rc-upload")) {
        console.log("upload file");
        const formData = new FormData();
        formData.append("files", file.fileInfo, file.fileName);
        try {
          const response = await axios({
            url,
            method: "post",
            headers: {
              Authorization: `Bearer ${tokenClient}`,
            },
            processData: false,
            data: formData,
          });
          newAttachments = [
            ...newAttachments,
            { fileId: response.data.fileId, fileName: file.fileName },
          ];
        } catch (error) {
          console.error(error);
        }
      }
    });

    const deletePromises = deleteFileList.map(async (fileId) => {
      if (otherToUpdate.attachments.some((file) => file.fileId === fileId)) {
        console.log("delete file");
        try {
          await API.deleteFileFromOther({ fileId });
          newAttachments = newAttachments.filter((item) => item.fileId !== fileId);
          //update deleteFileList state
        } catch (error) {
          console.error(error);
        }
      }
    });

    await Promise.all([...uploadPromises, ...deletePromises]);

    const res = await API.putOperation(client._id, idOMD, {
      text: updatedItem.content,
      attachments: newAttachments,
      date: new Date(),
    });

    if (res.status === 201) {
      const newList = [
        ...otherMissingDocuments.map((op) =>
          op._id === idOMD
            ? {
                _id: idOMD,
                text: updatedItem.content,
                attachments: newAttachments,
                date: new Date(),
              }
            : op
        ),
      ];
      setOtherMissingDocuments(newList);
      //TODO: fix empty state before card rendering
      cancelEditing();
      dispatch(updateClient({ otherMissingDocuments: newList }));
    } else {
      openNotification("error", "Impossible de mettre à jour l'opération");
    }
  };

  const deleteOMD = (idOMD) => {
    const otherToDelete = otherMissingDocuments.find((op) => op._id === idOMD);

    if (!otherToDelete) {
      console.error(`No document found with id ${idOMD}`);
      openNotification("error", "Document non trouvé");
      return;
    }

    (async () => {
      if (otherToDelete.attachments && otherToDelete.attachments.length > 0) {
        for (const file of otherToDelete.attachments) {
          try {
            await API.deleteFileFromOther({ fileId: file.fileId });
          } catch (error) {
            console.error(error);
          }
        }
      } else if (otherToDelete.files && otherToDelete.files.length > 0) {
        for (const file of otherToDelete.files) {
          const res = await API.deleteMissingDocumentFile(file._id);
          if (res.status === 201) {
            console.log("File deleted: " + file._id);
          } else console.log("Error deleteMissingDocumentFile");
        }
      }

      try {
        await API.deleteOperation(client._id, idOMD);
        const newList = otherMissingDocuments.filter((op) => op._id !== idOMD);
        setOtherMissingDocuments(newList);
        dispatch(updateClient({ otherMissingDocuments: newList }));
      } catch (error) {
        console.error("Error deleting operation:", error);
        openNotification("error", "Erreur lors de la suppression de l'opération");
      }
    })();
  };

  function confirmDeletion(onConfirm) {
    confirm({
      title:
        "La suppression de la pièce entraînera son retrait des pièces manquantes ainsi que des pièces reçues. Assurez-vous d'avoir bien téléchargé les pièces avant de valider.",
      icon: <ExclamationCircleOutlined style={{ bottom: "45px" }} />,
      className: "modal-lost modal-others",
      okText: "Valider",
      cancelText: "Annuler",
      closable: true,
      onOk() {
        onConfirm();
      },
    });
  }

  return (
    <>
      <div className="type-settings" style={{ marginBottom: "20px" }}>
        <b>Listes prédéfinies :</b>
        <Select
          placeholder={"Sélectionner une liste prédéfinie à ajouter"}
          className="model-select"
          onSelect={(value) => {
            setSelectedList(listOthers.find((elt) => elt._id === value));
          }}
          notFoundContent={"Aucune donnée disponible"}
          popupClassName="ant-select-dropdown-zindex"
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          {listOthers.map((item, i) => {
            return (
              <Option value={item._id} key={i}>
                {item.name}
              </Option>
            );
          })}
        </Select>
        <Button
          icon={<PlusOutlined />}
          disabled={Object.keys(selectedList).length === 0}
          className="call-action-btn"
          style={{
            marginLeft: "20px",
            ...(Object.keys(selectedList).length === 0 ? { opacity: 0.5 } : {}),
          }}
          onClick={() => addOthersFromList()}
        >
          Ajouter
        </Button>
      </div>
      {otherMissingDocuments?.map((other, index) => {
        return (
          <div style={{ marginBottom: "20px" }} key={index}>
            {isEditing === index ? (
              <div
                style={{
                  borderRadius: "20px",
                  boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                }}
              >
                <TipTapEditor
                  content={editedContent}
                  setOtherInfo={(updatedData) => updateOMD(other._id, updatedData)}
                  fileList={selectedFileList}
                  setFileList={setSelectedFileList}
                  cancelEditing={cancelEditing}
                  deleteFile={(data) => setDeleteFileList([...deleteFileList, data])}
                />
              </div>
            ) : (
              <Card
                key={index}
                style={{
                  border: "2px solid #C7E0FF",
                  background: "#EAF4FE",
                }}
                className="other-card"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: other.text }}
                  style={{ display: "inline-block", width: "90%" }}
                />

                {other.attachments && other.attachments.length > 0 && (
                  <>
                    <Divider style={{ margin: "15px 0 10px 0" }} />
                    <div style={{ display: "block", width: "90%" }}>
                      {other.attachments.map((file, fileIndex) => (
                        <Tag
                          key={fileIndex}
                          style={{
                            cursor: "pointer",
                          }}
                          color="grey"
                          icon={<PaperClipOutlined />}
                          onClick={async () => await handleAttachmentDownload(file)}
                        >
                          {file.fileName}
                        </Tag>
                      ))}
                    </div>
                  </>
                )}
                <div style={{ position: "absolute", right: "10px", top: "10px" }}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    style={{ marginRight: "10px" }}
                    onClick={() => startEditing(index, other.text, other.attachments)}
                  />
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      //add files to deleFileList
                      confirmDeletion(() => {
                        deleteOMD(other._id);
                      });
                    }}
                  />
                </div>
              </Card>
            )}
          </div>
        );
      })}
      <Divider style={{ margin: "15px 0 10px 0" }} />
      <TipTapEditor
        setOtherInfo={(data) => addOMD(data)}
        fileList={selectedFileList}
        setFileList={setSelectedFileList}
        deleteFile={(data) => setDeleteFileList([...deleteFileList, data])}
      />
    </>
  );
}

export default Other;
