import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Popover, Row } from "antd";
import * as dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { selectClient, updateClient } from "../../../../slices/clientSlice";
import * as number from "../../../../utils/number";
import CommentCollab from "./CommentCollab";

function Operation(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const onChange = (state) => {
    if (
      client.uncheckedAccounts?.includes(
        props.category.accountNumberAux + "-" + (props.operation.amount > 0 ? 2 : 1)
      ) &&
      state
    ) {
      dispatch(
        updateClient({
          uncheckedAccounts: client.uncheckedAccounts.filter(
            (cat) =>
              cat !== props.category.accountNumberAux + "-" + (props.operation.amount > 0 ? 2 : 1)
          ),
        })
      );
    }

    const opsInThisCategory = props.category.operations;
    const operationIndex = props.category.operations.findIndex(
      (op) => op._id === props.operation._id
    );

    props.setAllData((prev) =>
      prev.map((el) =>
        el.id === props.id
          ? {
              ...el,
              data: [
                ...props.data.filter((cat) => cat._id !== props.category._id),
                {
                  ...props.category,
                  operations: [
                    ...opsInThisCategory.slice(0, operationIndex),
                    {
                      ...opsInThisCategory[operationIndex],
                      active: state,
                    },
                    ...opsInThisCategory.slice(operationIndex + 1),
                  ],
                },
              ],
            }
          : el
      )
    );
  };

  return (
    <div key={props.operation._id}>
      <span className="operation">
        <div
          className={
            props.operation.ghost === 1 &&
            (props.operation.fileId ||
              props.operation.files?.length > 0 ||
              props.operation.comment ||
              props.operation.isLost ||
              props.operation.isLostNoAccount)
              ? "site-collapse-custom-text-block-ghost"
              : props.operation.active !== false
              ? "site-collapse-custom-text-block"
              : "site-collapse-custom-text-block-grey"
          }
        >
          <Row className="op-row">
            {props.editMode ? (
              <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                checked={props.operation.active !== false}
              />
            ) : null}
            <Col xs={22} sm={22} md={22} lg={3} xl={3}>
              <span className="data-op">{dayjs(props.operation.date).format("DD/MM/YYYY")}</span>
            </Col>
            <Col xs={22} sm={22} md={22} lg={10} xl={10}>
              {props.operation.text || "[Absence de libellé]"}
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4} className="piece-ref">
              {props.operation.pieceRef &&
              props.operation.pieceRef !== "NONE" &&
              props.operation.pieceRef !== "NAN"
                ? props.operation.pieceRef
                : ""}
            </Col>
            <Col xs={10} sm={10} md={10} lg={6} xl={6} offset={props.editMode ? 0 : 1}>
              <div className="div-received-btn">
                <span className="operation-amount">
                  {number.withThousandSeparator(number.parseNum(Math.abs(props.operation.amount)))}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </span>
      {props.operation.ghost === 1 &&
      (props.operation.fileId ||
        props.operation.files?.length > 0 ||
        props.operation.comment ||
        props.operation.isLost ||
        props.operation.isLostNoAccount) ? (
        <Popover
          placement="bottom"
          dropdownAlign={{ overflow: { adjustY: false } }}
          content={
            <span>
              Nous détectons une pièce reçue mais nous ne parvenons pas à retrouver{" "}
              <b>l’identifiant</b> correspondant à cette opération dans les nouvelles données
              transmises par votre outil de production. <br />
              Nous vous invitons à :
              <ul>
                <li>
                  la <b>télécharger</b> si nécessaire dans l’onglet “pièces reçues”
                </li>
                <li>
                  la <b>supprimer</b> dans l’onglet “pièces reçues” si celle-ci est déjà lettrée
                </li>
              </ul>
              Sans action de votre part, la pièce sera automatiquement supprimée le{" "}
              <b>{props.operation.ghostDeleteDate}</b>. Pour plus d’informations, n’hésitez pas nous
              contacter.{" "}
            </span>
          }
          className="ghost-popover"
          overlayClassName="ghost-popover-overlay"
        >
          <span className="ghost-op-info-missing">
            Nous ne parvenons pas à trouver l’identifiant correspondant à cette pièce.{" "}
            <ExclamationCircleOutlined />
          </span>
        </Popover>
      ) : null}
      <CommentCollab {...props} operation={props.operation} />
    </div>
  );
}

export default Operation;
