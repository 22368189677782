import { DownCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ImportantIcon } from "../../../../../assets/images/green-circle.svg";
import { selectClient } from "../../../../../slices/clientSlice";
import * as number from "../../../../../utils/number";
import Category from "./Category";
import TableJournals from "./TableJournals";

function Type(props) {
  const client = useSelector(selectClient);

  const [categories, setCategories] = useState([]);
  const [activeOperations, setActiveOperations] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [nbrMissingDoc, setNbrMissingDoc] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    console.debug("useEffect init/update data");
    setCategories(
      props.data.map((cat) => ({
        ...cat,
        key: cat._id || cat.id,
        numberMissingDoc: cat.operations.length,
        amount: cat.operations.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
      }))
    );
  }, [JSON.stringify(props.data)]);

  useEffect(() => {
    if (categories.length === 0) return;
    console.debug("useEffect update totals");

    let totalAmount = 0;
    let nbrMissingDoc = 0;
    categories.forEach(({ numberMissingDoc, amount }) => {
      totalAmount += Math.abs(amount);
      nbrMissingDoc += numberMissingDoc;
    });

    setActiveOperations(
      categories.map(({ operations }) => operations.filter((op) => op.active !== false)).flat(1)
    );
    setTotalAmount(totalAmount);
    setNbrMissingDoc(nbrMissingDoc);
  }, [JSON.stringify(categories)]);

  useEffect(() => {
    setExpandedRows(props.allExpanded ? categories.map((cat) => cat._id) : []);
  }, [props.allExpanded]);

  const accountColumns = [
    {
      title: "",
      width: "30px",
      render: () => (
        <span className="operation-title">
          <ImportantIcon className="operation-title-circle" />
        </span>
      ),
    },
    {
      title: "N° de compte aux.",
      dataIndex: "accountNumberAux",
      key: "accountNumberAux",
      width: props.mode === 3 ? "130px" : "170px",
      defaultSortOrder: "ascend",
    },
    {
      title: "Lib. compte aux.",
      dataIndex: "title",
      key: "title",
      width: props.mode === 3 ? "110px" : "150px",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Justificatifs manquants",
      dataIndex: "numberMissingDoc",
      key: "numberMissingDoc",
      align: "center",
      width: "120px",
      sorter: (a, b) => b.numberMissingDoc - a.numberMissingDoc,
    },
    {
      title: "Montant total",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "100px",
      sorter: (a, b) => b.amount - a.amount,
      render: (text) => (
        <div style={{ textAlign: "right" }}>
          {number.withThousandSeparator(number.parseNum(text))}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <>
      {props.mode === 3 ? (
        <>
          <div className="type-title">
            {props.id} - {props.label}
          </div>
          <div className="type-separation"></div>
          <Table
            className="missing-table  journal-missing-table"
            dataSource={[]}
            columns={accountColumns}
            pagination={false}
            locale={{ emptyText: " " }}
          />
          <TableJournals {...props} categories={categories} accountColumns={accountColumns} />
        </>
      ) : (
        (totalAmount > 0 || props.editMode) && (
          <div className="type-missing-doc">
            <div className="type-title">
              {props.label} - {number.withThousandSeparator(number.parseNum(totalAmount))}
            </div>
            <div className="type-separation"></div>
            <Table
              className="missing-table"
              dataSource={categories}
              columns={accountColumns}
              expandable={{
                expandedRowRender: (record) => (
                  <Category {...props} category={record} key={record._id} />
                ),
                expandedRowKeys: expandedRows,
                expandRowByClick: !props.editMode,
                onExpandedRowsChange: (rows) => setExpandedRows(rows),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <DownCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className={expanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                ),
              }}
            />
          </div>
        )
      )}
    </>
  );
}

export default Type;
