import {
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleTwoTone,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Carousel, Col, Modal, Popover, Row, Spin, Tooltip } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { ReceivedDocHelper } from "helpers/analysis/received/receivedDocHelper";
import { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../../../api/API";
import ConciliatorLogoNotValid from "../../../../../assets/images/conciliator-error.png";
import ConciliatorLogoValid from "../../../../../assets/images/conciliator-valid.png";
import IDocusLogoNotValid from "../../../../../assets/images/idocusnotvalid.png";
import IDocusLogoValid from "../../../../../assets/images/idocusvalid.png";
import IDepotLogo from "../../../../../assets/images/logo-isuite-depot.png";
import MailToBoxLogoValid from "../../../../../assets/images/mailtobox.png";
import MCFLogoNotValid from "../../../../../assets/images/mcfnotvalid.png";
import MCFLogoValid from "../../../../../assets/images/mcfvalid.png";
import { selectAccountingFirm } from "../../../../../slices/accountingFirmSlice";
import { selectClient } from "../../../../../slices/clientSlice";
import { selectUser } from "../../../../../slices/userSlice";
import "../../../MissingDocuments.css";
import InvalidFile from "../../InvalidFile";
import PdfViewer from "../../PdfViewer";
import ResetOperation from "../../ResetOperation";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { confirm } = Modal;

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

function Operation(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const clientRemoteId = props.match.params.clientRemoteId;

  const [invalidated, setInvalidated] = useState([]);
  const [invalidComment, setInvalidComment] = useState([]);
  const [reseted, setReseted] = useState([]);
  const [downloadedOnce, setDownloadedOnce] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileShown, setFileShown] = useState();
  const [blobForPreview, setBlobForPreview] = useState();

  const filePreview = useRef();

  useEffect(() => {
    const dlOnce = [];
    if (client.status === "ready") {
      props.data.forEach((elt) => {
        elt.operations.forEach((op) => {
          if (op.downloadedOnce) dlOnce.push(op._id);
        });
      });

      const other = [];
      client.otherMissingDocuments?.forEach((op) => {
        if (op.fileId || op.comment || op.files?.length > 0) {
          other.push(op);
        }
        if (op.downloadedOnce) {
          dlOnce.push(op._id);
        }
      });
      setDownloadedOnce(dlOnce);
    }
  }, [props.minAmount, props.data, client.otherMissingDocuments, props.journalList]);

  function confirmGhostDeletion(categoryId, operation) {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-lost modal-others",
      okText: "Oui",
      cancelText: "Non",
      closable: true,
      onOk() {
        (async () => {
          await API.deleteGhostMissingDoc({
            clientId: client._id,
            clientRemoteId: clientRemoteId,
            operationId: props.operation._id,
            accountingFirmId: accountingFirm._id,
          });
        })();
        props.setGhostDocuments((previous) =>
          previous.filter((op) => op._id !== props.operation._id)
        );
        props.onDeleteOperation({ categoryId: categoryId, operationId: props.operation._id });
      },
    });
  }

  function openPreview(operation, categoryId) {
    setLoading(true);
    setFileShown(operation);
    setShowPreview(true);
    ReceivedDocHelper.previewFile(client, props.operation.files, props.operation._id).then(
      (returnedData) => {
        setBlobForPreview(returnedData);
        setLoading(false);
      }
    );
  }

  const closePreview = () => {
    setShowPreview(false);
    for (let i = 0; i < blobForPreview.length; i++) {
      URL.revokeObjectURL(blobForPreview[i]);
    }
  };

  return (
    <>
      <Modal
        title={null}
        footer={null}
        open={showPreview}
        onCancel={() => closePreview()}
        closable={false}
        className="modal-preview"
      >
        {fileShown?.files.length > 1 ? (
          <>
            <Button
              icon={
                <LeftCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.prev()}
              className="transparent-btn"
              style={{ left: "-50%", top: "250px" }}
            />
            <Button
              icon={
                <RightCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.next()}
              className="transparent-btn"
              style={{
                float: "right",
                right: "-10%",
                top: "250px",
              }}
            />
          </>
        ) : null}
        <Carousel ref={filePreview} dots={false}>
          {fileShown?.files.map((file, index) => {
            const fileExtension = file.fileName.split(".").pop();
            const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];

            if (!allowedExtensions.includes(fileExtension)) {
              return (
                <div>
                  <div className="no-preview" key={index}>
                    <span>
                      <EyeInvisibleOutlined style={{ fontSize: "1.7em" }} />
                      <p>Apperçu non disponible</p>
                      <p>{file.fileName}</p>
                    </span>
                  </div>
                </div>
              );
            }

            return (
              <>
                {loading ? (
                  <div className="no-preview">
                    <Spin size="large" />
                  </div>
                ) : (
                  <div key={index}>
                    <p
                      style={{
                        color: "#373737",
                        fontWeight: "bold",
                        marginTop: "0px",
                        position: "relative",
                        left: "-20%",
                      }}
                    >
                      {file.fileName}
                    </p>
                    {fileExtension === "pdf" ? (
                      <PdfViewer file={blobForPreview[index]} />
                    ) : (
                      <img
                        src={blobForPreview[index]}
                        alt="preview"
                        className="ratio-img"
                        style={{ margin: "auto", height: "auto !important" }}
                      />
                    )}
                  </div>
                )}
              </>
            );
          })}
        </Carousel>
      </Modal>
      <div>
        <span className="operation">
          <div
            className={
              props.operation.ghost === 1
                ? "site-collapse-custom-text-block-ghost"
                : invalidated.includes(props.operation._id) || reseted.includes(props.operation._id)
                ? "status-grey site-collapse-custom-text-block"
                : "site-collapse-custom-text-block"
            }
          >
            <Row className="op-row">
              <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                <span className="data-op">{dayjs(props.operation.date).format("DD/MM/YYYY")}</span>
              </Col>
              <Col xs={22} sm={22} md={7} lg={7} xl={7}>
                {props.operation.text ? props.operation.text : "[Absence de libellé]"}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="piece-ref">
                {props.operation.pieceRef &&
                props.operation.pieceRef !== "NONE" &&
                props.operation.pieceRef !== "NAN"
                  ? props.operation.pieceRef
                  : ""}
              </Col>
              <Col xs={10} sm={10} md={6} lg={6} xl={6} offset={1}>
                <div className="div-received-btn">
                  <span className="operation-amount">
                    {numberWithThousandSeparator(parseNum(Math.abs(props.operation.amount)))}
                  </span>

                  {invalidated.includes(props.operation._id) ? (
                    <span className="not-valid">Invalidé</span>
                  ) : reseted.includes(props.operation._id) ? (
                    <span className="not-valid">Réinitialisée</span>
                  ) : props.operation.fileId || props.operation.files.length > 0 ? (
                    <>
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          openPreview(props.operation, props.category._id);
                        }}
                        shape="circle"
                        icon={
                          <EyeOutlined
                            style={{
                              top: "1.5px",
                              position: "relative",
                            }}
                          />
                        }
                        style={{ margin: " 0 -12px 0 10px" }}
                      />{" "}
                      <Tooltip
                        title={
                          downloadedOnce.includes(props.operation._id)
                            ? "Télécharger à nouveau"
                            : "Télécharger"
                        }
                      >
                        <Button
                          shape="circle"
                          size="small"
                          className={
                            props.operation.ghost === 1
                              ? downloadedOnce.includes(props.operation._id)
                                ? "download-btn-ghost downloaded-once-ghost"
                                : "download-btn-ghost"
                              : downloadedOnce.includes(props.operation._id)
                              ? "download-btn downloaded-once"
                              : "download-btn "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            logAction(239, 1, client._id);
                            if (!downloadedOnce.includes(props.operation._id)) {
                              setDownloadedOnce([...downloadedOnce, props.operation._id]);
                            }
                            if (props.operation.files.length > 0) {
                              ReceivedDocHelper.downloadFiles(
                                client,
                                props.operation.files,
                                props.operation._id,
                                true
                              );
                            }
                          }}
                          icon={<DownloadOutlined />}
                        />
                      </Tooltip>
                    </>
                  ) : null}
                  {props.operation.ghost === 1 ? (
                    <Tooltip title="Supprimer" color="#FAAD14">
                      <Button
                        className="delete-ghost-btn"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          confirmGhostDeletion(props.category._id, props.operation);
                        }}
                        shape="circle"
                        icon={<CloseOutlined />}
                        style={{ marginLeft: "10px" }}
                      />
                    </Tooltip>
                  ) : !invalidated.includes(props.operation._id) &&
                    !reseted.includes(props.operation._id) ? (
                    <Popover
                      title=""
                      //placement="top"
                      align={{ offset: [10, -16] }}
                      overlayClassName="remove-op-popover"
                      content={
                        <>
                          <InvalidFile
                            {...props}
                            files={props.operation.files}
                            opId={props.operation._id}
                            categoryId={props.category._id}
                            other={false}
                            operationText={
                              props.operation.text ? props.operation.text : "[Absence de libellé]"
                            }
                            invalidated={(data) => {
                              setInvalidated([...invalidated, data.opId]);
                              setInvalidComment([
                                ...invalidComment,
                                [data.opId, data.invalidcomment],
                              ]);
                            }}
                          />
                          <ResetOperation
                            {...props}
                            files={props.operation.files}
                            opId={props.operation._id}
                            categoryId={props.category._id}
                            other={false}
                            operationText={
                              props.operation.text ? props.operation.text : "[Absence de libellé]"
                            }
                            reseted={(data) => {
                              setReseted([...reseted, data.opId]);
                            }}
                          />
                        </>
                      }
                    >
                      {" "}
                      <Button
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined />}
                        style={{ marginLeft: "10px" }}
                      />
                    </Popover>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="img-ged">
            {accountingFirm.mcf &&
            props.operation.files.length > 0 &&
            props.operation.files.length ===
              props.operation.files.filter((f) => f.receivedByMCF === true).length ? (
              <Popover
                placement="top"
                content="Justificatif chargé dans MyCompanyFiles"
                overlayClassName="mcf-success-popover"
              >
                <img src={MCFLogoValid} alt="MCF valid" className="mcf-logo" />
              </Popover>
            ) : accountingFirm.mcf &&
              props.operation.files.length > 0 &&
              props.operation.files.filter((f) => f.receivedByMCF === false).length > 0 ? (
              <Popover
                placement="top"
                content="Erreur de chargement dans MyCompanyFiles"
                overlayClassName="mcf-fail-popover"
              >
                <img src={MCFLogoNotValid} alt="MCF invalid" className="mcf-logo" />
              </Popover>
            ) : null}
            {accountingFirm.iDocus &&
            props.operation.files.length > 0 &&
            props.operation.files.length ===
              props.operation.files.filter((f) => f.receivedByIDocus === true).length ? (
              <Popover
                placement="top"
                content="Justificatif chargé dans iDocus"
                overlayClassName="mcf-success-popover"
              >
                <img src={IDocusLogoValid} alt="iDocus valid" className="idocus-logo" />
              </Popover>
            ) : accountingFirm.iDocus &&
              props.operation.files.length > 0 &&
              props.operation.files.filter((f) => f.receivedByIDocus === false).length > 0 ? (
              <Popover
                placement="top"
                content="Erreur de chargement dans iDocus"
                overlayClassName="mcf-fail-popover"
              >
                <img src={IDocusLogoNotValid} alt="iDocus invalid" className="idocus-logo" />
              </Popover>
            ) : null}
            {accountingFirm.conciliator &&
            props.operation.files.length > 0 &&
            props.operation.files.length ===
              props.operation.files.filter((f) => f.receivedByConciliator === true).length ? (
              <Popover
                placement="top"
                content="Justificatif chargé dans Conciliator"
                overlayClassName="mcf-success-popover"
              >
                <img
                  src={ConciliatorLogoValid}
                  alt="Conciliator valid"
                  className="conciliator-logo"
                />
              </Popover>
            ) : accountingFirm.conciliator &&
              props.operation.files.length > 0 &&
              props.operation.files.filter((f) => f.receivedByConciliator === false).length > 0 ? (
              <Popover
                placement="top"
                content="Erreur de chargement dans Conciliator"
                overlayClassName="mcf-fail-popover"
              >
                <img
                  src={ConciliatorLogoNotValid}
                  alt="Conciliator invalid"
                  className="conciliator-logo"
                />
              </Popover>
            ) : null}
            {accountingFirm.mailToBox &&
            (client.mailToBox || client.mailToBoxSales) &&
            props.operation.files.length > 0 &&
            props.operation.files.length ===
              props.operation.files.filter((f) => f.receivedByMailToBox === true).length ? (
              <Popover
                placement="top"
                content="Justificatif envoyé par Mail to Box"
                overlayClassName="mcf-success-popover"
              >
                <img src={MailToBoxLogoValid} alt="Mail to Box valid" className="mcf-logo" />
              </Popover>
            ) : null}
            {accountingFirm.iDepot &&
            props.operation.files.length > 0 &&
            props.operation.files.length ===
              props.operation.files.filter((f) => f.receivedByIDepot === true).length ? (
              <Popover
                placement="top"
                content="Justificatif déposé dans la panière"
                overlayClassName="mcf-success-popover"
              >
                <img src={IDepotLogo} alt="IDepot valid" className="mcf-logo" />
              </Popover>
            ) : null}
          </div>
        </span>
        {props.operation.ghost === 1 ? (
          <Popover
            placement="bottom"
            dropdownAlign={{ overflow: { adjustY: false } }}
            content={
              <span>
                Nous ne parvenons pas à retrouver <b>l’identifiant </b>
                correspondant à cette pièce dans les nouvelles données transmises par votre outil de
                production. <br />
                Nous vous invitons à :
                <ul>
                  <li>
                    la <b>télécharger</b> si nécessaire
                  </li>
                  <li>
                    la <b>supprimer</b> si celle-ci est déjà lettrée
                  </li>
                </ul>
                Sans action de votre part, la pièce sera automatiquement supprimée le{" "}
                <b>{props.operation.ghostDeleteDate}</b>. Pour plus d’informations, n’hésitez pas
                nous contacter.{" "}
              </span>
            }
            className="ghost-popover"
            overlayClassName="ghost-popover-overlay"
          >
            <span className="ghost-op-info-received">
              Nous ne parvenons pas à trouver l’identifiant correspondant à cette pièce.{" "}
              <ExclamationCircleOutlined />
            </span>
          </Popover>
        ) : null}
        <div className="comment-div">
          {invalidated.includes(props.operation._id) ? (
            <p>
              Invalidation collaborateur ({user.firstName} {user.lastName}) :{" "}
              {invalidComment.find((comment) => comment[0] === props.operation._id)?.[1]}
            </p>
          ) : null}
          {props.operation.isLost || props.operation.isLostNoAccount ? (
            <p className="islost">
              {props.operation.isLost ? "Justificatif perdu – Récupérable" : null}
              {props.operation.isLostNoAccount ? "Justificatif perdu – Non récupérable" : null}
            </p>
          ) : null}
          {props.operation.nature ? (
            <p className="nature">Nature du mouvement : {props.operation.nature}</p>
          ) : null}
          {props.operation.comment ? (
            <p>
              <b>Commentaire client : </b> {props.operation.comment}
            </p>
          ) : null}
          {props.operation.commentCollab && (
            <p>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Popover
                  placement="right"
                  content={
                    <span className="popover-comment-text">
                      <b>Commentaire cabinet : </b>
                      {props.operation.commentCollab}
                    </span>
                  }
                  className="popover-comment"
                >
                  <InfoCircleTwoTone twoToneColor="#4569f8" style={{ fontSize: "120%" }} />
                </Popover>
              </span>
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Operation;
