import { Skeleton } from "antd";
import "dayjs/locale/fr";
import {
  filterView1Helper,
  filterView2Helper,
  filterView3Helper,
  filterViewOthersHelper,
} from "helpers/client/clientMissingDocHelper";
import { useEffect, useState } from "react";
import Type from "./desktop/Type";
import ClientMissingOthers from "./others";
import ClientMissingTables from "./responsive/tablesResponsive";
import ClientBodyStats from "./statistics";

function ClientMissingBody(props) {
  const [view, setView] = useState(1);
  const [closeDateIsSelected, setCloseDateIsSelected] = useState([true, true, true]);
  const [filteredMissingDocOther, setFilteredMissingDocOther] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setView(props.missingDocDefaultView ?? 1);
  }, [props.missingDocDefaultView]);

  useEffect(() => {
    if (!props.missingDocument) return;
    let missingDoc;
    switch (view) {
      case 1:
        missingDoc = filterView1Helper(
          props.missingDocument,
          closeDateIsSelected,
          props.fiscalStartMonth
        );
        break;
      case 2:
        missingDoc = filterView2Helper(
          props.missingDocument,
          closeDateIsSelected,
          props.fiscalStartMonth
        );
        break;
      default:
        missingDoc = filterView3Helper(
          props.missingDocument,
          closeDateIsSelected,
          props.fiscalStartMonth
        );
    }
    setDataSource(missingDoc);
  }, [props.missingDocument, closeDateIsSelected, view]);

  useEffect(() => {
    if (!props.missingDocumentOther) return;
    const missingDocOther = filterViewOthersHelper(
      props.missingDocumentOther,
      closeDateIsSelected,
      props.fiscalStartMonth
    );
    setFilteredMissingDocOther(missingDocOther);
  }, [props.missingDocumentOther, closeDateIsSelected]);

  return (
    <section id="client-missing">
      {props.missingDocHeaderMessage ? (
        <span dangerouslySetInnerHTML={{ __html: props.missingDocHeaderMessage }} />
      ) : (
        <span>
          <p>
            Bonjour
            <br />
            Voici la liste des mouvements pour lesquels un justificatif est manquant. Nous vous
            invitons à transmettre la pièce correspondante et à indiquer la nature du mouvement le
            cas échéant.
          </p>
        </span>
      )}
      {dataSource.length === 0 || !filteredMissingDocOther ? (
        <Skeleton active />
      ) : (
        <ClientBodyStats
          {...props}
          missingDocument={dataSource}
          missingDocumentOther={filteredMissingDocOther.data}
          lastMailSent={props.lastMailSent}
          closeDateIsSelected={closeDateIsSelected}
          setCloseDateIsSelected={setCloseDateIsSelected}
          view={view}
          setView={setView}
          screenWidth={props.screenWidth}
        />
      )}

      {!filteredMissingDocOther ? (
        <Skeleton active />
      ) : (
        <ClientMissingOthers
          missingDocumentOther={filteredMissingDocOther.data}
          setMissingDocumentOther={props.setMissingDocumentOther}
          screenWidth={props.screenWidth}
          tokenClient={props.tokenClient}
        />
      )}
      {!dataSource ? (
        <Skeleton active />
      ) : props.screenWidth <= 900 ? (
        dataSource.map((item) => (
          <ClientMissingTables
            key={item.id}
            type={item.id}
            missingDocument={item.data}
            setMissingDocument={props.setMissingDocument}
            label={item.label}
            tokenClient={props.tokenClient}
            missingDocLostMessage={props.missingDocLostMessage}
          />
        ))
      ) : (
        dataSource.map((item) => (
          <Type
            key={item.id}
            id={item.id}
            view={view}
            missingDocument={item.data}
            label={item.label}
            tokenClient={props.tokenClient}
            missingDocLostMessage={props.missingDocLostMessage}
          />
        ))
      )}
    </section>
  );
}

export default ClientMissingBody;
