import { ShopOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";

function ClientNavbar(props) {
  const [clientName, setClientName] = useState("");
  const [logo, setLogo] = useState();

  useEffect(() => {
    (async () => {
      try {
        let res = await fetch(
          process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + props.idClient,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${props.tokenClient}` },
          }
        );
        res = await res.json();
        setClientName(res.name);

        res = await API.getAccountingFirmLogo(props.accountingFirmId);
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <nav>
      <div>
        {logo ? (
          <img src={logo} alt="logo" style={{ maxWidth: "150px", maxHeight: "80px" }} />
        ) : null}
        <span>
          <Button shape="circle" default icon={<ShopOutlined />} />
          <p>
            <b>{clientName}</b>
          </p>
        </span>
      </div>
      {!props.quitPage ? (
        <Button
          type="text"
          onClick={() => {
            props.setQuitPage(true);
          }}
        >
          Quitter
        </Button>
      ) : null}
    </nav>
  );
}

export default ClientNavbar;
